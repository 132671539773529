'use strict';

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      // .length of function is 2
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }
      var to = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];
        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}
var layout = function () {
  function init() {
    setScrollbarWidth();
  }
  function setScrollbarWidth() {
    document.documentElement.style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px');
  }
  function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  window.addEventListener("resize", function (event) {
    setScrollbarWidth();
  });
  return {
    init: init
  };
}();
layout.init();
var link = function () {
  /*
   * Replaces masked email addresses
   * Schemes for addresses
   *   - name (at) domain (.) com => <a href="mailto:name@domain.com">name@domain.com</a>
   *   - Label [name (at) domain (.) com] => <a href="mailto:name@domain.com">Label</a>
   */

  var defaults = {
    selectors: {
      email: ".epost"
    },
    patterns: {
      at: new RegExp(/ ?\(at\) ?/),
      dot: new RegExp(/ ?\(\.\) ?/)
    }
  };

  // Email adresses

  var getEmail = function getEmail(text) {
    var email = {
      label: text,
      href: ""
    };
    if (typeof text !== "string" || text === "" || !email.label.match(defaults.patterns.at) || !email.label.match(defaults.patterns.dot)) {
      return email;
    }
    email.label = email.label.replace(defaults.patterns.at, "@");
    email.label = email.label.replace(defaults.patterns.dot, ".");
    email.href = email.label;

    // Email address in brackets

    if (email.label.search(/\[.*\]/) !== -1) {
      var results = email.label.match(/(.*?) ?\[(.*)\]/);
      email.href = results[2] ? results[2] : email.label;
      email.label = results[1] ? results[1] : email.href;
    }
    email.href = "mailto:" + email.href;
    return email;
  };
  function init() {
    var elements = document.querySelectorAll(defaults.selectors.email);
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i],
        email = getEmail(element.innerText);
      element.innerText = email.label;
      if (element.title !== "") {
        email.href += "?subject=" + element.title;
      }
      var classes = "";
      element.classList.remove(defaults.selectors.email);
      classes = element.getAttribute("class", "");
      element.setAttribute("class", "");
      if (email.href) {
        var _link = document.createElement('a');
        _link.setAttribute("href", email.href);
        _link.innerText = email.label;
        _link.setAttribute("class", classes);
        element.innerText = "";
        element.appendChild(_link);
      }
    }
  }
  return {
    init: init
  };
}();
link.init();